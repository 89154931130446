<template>
<div>
  Stream:
  <video :srcObject.prop="stream" autoplay playsinline muted/>
  <broadcast 
    :channel="channel"
    :stream="stream"
    :config="{debugVolumeMuted: false}"
    @users-change="onUsersChange"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("test-sfu")
import broadcast from '@/components/agoraRTC.vue'
import { getUserMedia } from "@/services/mediautils"

export default {
  components: { 
    broadcast,
  },
  data() {
    return {
      users: null,
      channel: "test-sfu",
      stream: null,
    };
  },
  beforeCreate() {
    this.$debug.isOn = true;
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      log.log("init");
      this.stream = await getUserMedia({video:true, audio:true, definition: 'low'});
    },
    onUsersChange(users) {
      this.users = users;
    },
  }
}
</script>

<style>

</style>